<template>
  <div class="login pt-5">
    <div class="login__containerLogin text-center">
      <Loader v-if="performingRequest" />
      <transition name="fadeStop">
        <div style="width:40%; min-width:300px; margin:auto;" v-if="showPasswordReset">
          <PasswordReset @close="hidePassword()" />
        </div>
      </transition>
      <transition name="fadeStop">
        <div class="dashboard__container--body--col shiftCard" v-if="!showPasswordReset" style="margin: auto;">
          <div class="pa-5 pt-5" :class="signupForm" >
            <form v-if="showLoginForm" @submit.prevent>
              <h2>Welcome Back</h2>
              <div class="mb-3 mt-5">
                <label for="email1">Email</label>
                <input v-model.trim="loginForm.email" autocomplete="email" type="text" placeholder="you@email.com" id="email1" minlength="7" required />
              </div>
              <div class="mb-3">
                <label for="password1">Password</label>
                <input v-model.trim="loginForm.password" autocomplete="current-password" type="password" placeholder="******" id="password1" minlength="6" required />
              </div>
                <button @click="loginUser()" class="btn btn__primary btn__large mt-3">
                  Log In
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>

              
              <div class="extras mt-5 flex justify-space-evenly">
                <button class="btn btn__small btn__flat" @click="showPassword()">
                  Forgot Password
                </button>
                <button class="btn btn__small btn__flat" @click="toggleForm()">
                  Create an Account
                </button>
              </div>

              <div class="mt-4 caption">
                By clicking Log In, you agree to our <a href="/privacy">Privacy Policy</a>, <a href="/terms">Terms of Use</a>, and <a href="/guidelines">Community Guidelines</a>.
              </div>

              
            </form>

            <form v-if="!showLoginForm" @submit.prevent>
              <h2>Sign Up To Work</h2>
              <div class="mb-3 mt-5">
                <label for="firstname">First Name</label>
                <input v-model.trim="signupForm.firstname"  type="text" placeholder="" id="firstname" required />
              </div>
              <div class="mb-3">
                <label for="lastname">Last Name</label>
                <input v-model.trim="signupForm.lastname"  type="text" placeholder="" id="lastname" required />
              </div>
               <div class="mb-3">
                <label for="phone">Cell Phone</label>
                <input v-model.trim="signupForm.phone" @keyup="numCheck()" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
              </div>
              <div class="mb-3">
                <label for="email2">Email</label>
                <input v-model.trim="signupForm.email"  type="text" placeholder="you@email.com" id="email2" required minlength="7" />
              </div>
              <div class="mb-3">
                <label for="dob">Date of Birth:</label>
                <div style="width:100%;">
                  <input type="date" placeholder="" v-model.trim="signupForm.dob" id="dob" required/>
                </div>
              </div>
              <div class="mb-3">
                <label for="howhear">How did you hear about us?</label>
                <input v-model.trim="signupForm.howhear" type="text" placeholder="" id="howhear" />
              </div>
              <div class="mb-5">
                <label for="password2">Password</label>
                <input v-model.trim="signupForm.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6" />
              </div>

              <div class="mb-5 flex flex-row flex-start">
                <label for="messaging">I agree to receive SMS messages for job opportunities and placement details:</label>
                <input v-model.trim="signupForm.optIn" type="checkbox" class="mt-2 ml-4" />
              </div>

              <button :disabled="!valid2" @click="signupUser()" class="btn btn__primary btn__large mt-3">
                Sign Up
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>

              <div class="extras mt-5">
                <button class="btn btn__small btn__flat" @click="toggleForm()">
                  I already have an account
                </button>
              </div>
              <div class="mt-3 mb-3 caption">
                By clicking Sign Up, you agree to our <a href="/privacy">Privacy Policy</a>, <a href="/terms">Terms of Use</a>, and <a href="/guidelines">Community Guidelines</a>.
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<style scoped type="text/css">
  label {
    float: left;
  }
  .login {
    padding-top: 4rem;
  }
  .dashboard__container--body--col {
    margin-top: 2rem;
  }
  .signupForm {
    width: 96%;
    max-width: 100%;
    margin: auto;
}
</style>

<script>
import PasswordReset from '@/components/PasswordReset'
import Loader from '@/components/Loader'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'loginNew',
  components: {
    PasswordReset,
    Loader
  },
  data() {
    return {
      performingRequest: false,
      options: ['yes', 'no'],
      loginForm: {
        email: '',
        password: ''
      },
      bizForm: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
        company: '',
      },
      signupForm: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
        howhear: '',
        fullyVaccinated: '',
        dob: '',
        optIn: true
      },
      showLoginForm: true,
      showBusForm: false,
      showPasswordReset: false
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    valid() {
      if ((this.loginForm.email.length > 6) && (this.loginForm.password.length > 5)) {
        return true
      }
      else {
        return false
      }
    },
    valid2() {
      if ((this.signupForm.email.length > 6) && (this.signupForm.password.length > 5) && (this.signupForm.firstname.length > 0) && (this.signupForm.firstname.length > 0) && (this.signupForm.phone.length == 14) && (this.signupForm.dob) && (this.signupForm.optIn)) {
        return true
      }
      else {
        return false
      }
    },
    valid3() {
      if ((this.bizForm.email.length > 6) && (this.bizForm.password.length > 5) && (this.bizForm.firstname.length > 0) && (this.bizForm.firstname.length > 0) && (this.bizForm.phone.length == 14) && (this.bizForm.company)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
    // userCheck() {
    //   if (this.userProfile && this.userProfile.id) {
    //     this.$router.push('/jobs/apply')
    //   }
    // },
    numCheck() {
      console.log(this.signupForm.phone)
      let first = this.signupForm.phone.charAt(1);
      
      if (first == 1) {
        console.log('first', first)
        return this.signupForm.phone = (this.signupForm.phone.slice(0, 1) + this.signupForm.phone.slice(2))
      }
    },
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    showPassword() {
      this.showPasswordReset = true
    },
    hidePassword() {
      this.showPasswordReset = false
    },
    loginUser() {
      console.log('logging in')
      this.performingRequest = true
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
      // if (this.userProfile && this.userProfile.email) {
      //   this.$router.push('/jobs/apply')
      // }
    },
    signupUser() {
      this.performingRequest = true
      console.log('signing up')
      this.$store.dispatch('signupUser', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        firstName: this.signupForm.firstname,
        phone: this.signupForm.phone,
        lastName: this.signupForm.lastname,
        howhear: this.signupForm.howhear,
        dob: this.signupForm.dob,
      })
      // setTimeout(() => {
      //   this.$router.push('/auth/verify')
      // }, 1000)
    }
  },
  // mounted () {
  //   if (this.currentUser) {
  //    this.$router.push('/profile')
  //   }
  // }
  beforeDestroy() {
    this.performingRequest = false
    delete this.performingRequest
  }
}
</script>